<!--
 * @Author: Libra
 * @Date: 2024-04-08 11:13:18
 * @LastEditors: Libra
 * @Description: 中国电信定制页面
-->
<template>
  <div class="telecom-container">
    <div class="header">
      <img class="logo" src="@/assets/images/telecom.jpg" alt="">
    </div>
    <div class="telecom-content">
      <div class="content">
        <div class="left">
          <div class="top">
            <div class="avatar">
              {{ name.charAt(0) }}
            </div>
            <div class="name">{{ name }}</div>
            <div class="num">
              <span class="title">员工号：</span><span class="num-content">{{ degree }}</span>
            </div>
          </div>
          <div class="bottom">
            <div class="pdf" @click="downLoadPDF">
              <i class="iconfont iconcaozuozhinan" style="font-size: 36px; margin-right: 5px;"></i>
              <span style="font-weight: bold; color: #000;">查看考试系统指引</span>
            </div>
          </div>
          <!-- <div class="warn">
            <i class="el-icon-warning" style="color: #f35a5a; margin-right: 10px;"></i>
            <div style="color: #f35a5a;">考试开考前20分钟<br /> 即可点击进入考试系统</div>
          </div> -->
        </div>
        <div class="right">
          <div class="exam-item" v-for="item in examList" :key="item.examId">
            <div class="item-left">
              <div class="item-title">{{ item.examName }}</div>
              <div class="item-info">
                <div class="info-detail">
                  <span style="color: #a7a7a7;">考试时间：</span><span style="color: #7d7d7d;">{{ timeTrans(item.startAt) }}</span>
                </div>
                <div class="info-detail">
                  <span style="color: #a7a7a7;">考试状态：</span><span
                    :style="getStatus(item.startAt, item.endAt) === '考试中' ? 'color: #f35a5a;' :  'color: #7d7d7d;'"
                  >{{ getStatus(item.startAt, item.endAt) }}</span>
                </div>
                <div class="info-detail">
                  <span style="color: #a7a7a7;">考试时长：</span><span style="color: #7d7d7d;">{{item.duration/60}}分钟</span>
                </div>
                <div class="info-detail">
                  <span style="color: #a7a7a7;">考试成绩：</span><span style="color: #7d7d7d;">{{item.finalScore || '--'}}分</span>
                </div>
              </div>
            </div>
            <div class="item-right">
              <el-button style="width: 98px;" v-if="getStatus(item.startAt, item.endAt) === '已结束'" type="info" disabled>已结束</el-button>
              <el-button @click="goExam(item)" v-else type="primary">进入考试</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from '@/api/api'
import { file_host } from '../../api/config'
import store from '@/store'

export default {
  name: 'Telecom',
  created() {
    store.commit('resetToken')
    const { thirdPartySn, sign, timestamp } = this.$route.query
    this.getExamList(thirdPartySn, sign, timestamp)
  },
  data() {
    return {
      examList: [],
      name: '',
      thirdPartySn: '',
      degree: ''
    }
  },
  methods: {
    timeTrans(timestamp) {
      var date = new Date(timestamp)
      var year = date.getFullYear()
      var month = ('0' + (date.getMonth() + 1)).slice(-2)
      var day = ('0' + date.getDate()).slice(-2)
      var hours = ('0' + date.getHours()).slice(-2)
      var minutes = ('0' + date.getMinutes()).slice(-2)
      var formattedDateTime = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes
      return formattedDateTime
    },
    async getExamList(thirdPartySn, sign, timestamp) {
      const res = await Api.getResumePaperList({
        thirdPartySn,
        sign,
        timestamp
      })
      if (res.code === 0) {
        const { data } = res
        this.examList = data.list.sort((a, b) => {
          return new Date(b.startAt).getTime() - new Date(a.startAt).getTime()
        })
        this.degree = this.examList[0].degree
        this.name = data.realName
        this.thirdPartySn = data.thirdPartySn
      }
    },
    getStatus(start, end) {
      const now = new Date().getTime()
      const startAt = new Date(start).getTime()
      const endAt = new Date(end).getTime()
      if (now < startAt) {
        return '未开始'
      } else if (now > endAt) {
        return '已结束'
      } else {
        return '考试中'
      }
    },
    async goExam(item) {
      const res = await Api.getExamToken({
        examId: item.examId,
        realName: this.name,
        thirdPartySn: this.thirdPartySn
      })
      if (res.code === 0) {
        const { token, examShortId } = res.data
        this.$router.push({
          path: '/login',
          query: {
            token,
            shortId: examShortId
          }
        })
      } else if (res.code === 30003) {
        this.$message.error('您的考试已经结束！')
      }
    },
    downLoadPDF() {
      window.open(`${file_host}ct_intro.pdf`)
    }
  }
}
</script>

<style lang="scss" scoped>
.telecom-container {
  width: 100vw;
  height: 100vh;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .header {
    width: 100%;
    height: 80px;
    background-color: #fff;
    box-shadow: 0 3px 14px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    .logo {
      height: 64px;
    }
  }
  .telecom-content {
    flex: 1;
    height: 0px;
    width: 100%;
    background-color: #f7f7f7;
    .content {
      width: 1230px;
      height: 100%;
      margin: 0 auto;
      padding: 20px;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      .left {
        width: 250px;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .top {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          padding: 20px;
          border-radius: 10px;
          background-color: #fff;
        }
        .bottom {
          width: 100%;
          margin-top: 10px;
          padding: 5px 10px;
          border-radius: 10px;
          background-color: #fff;
        }
        .avatar {
          width: 64px;
          height: 64px;
          border-radius: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 36px;
          color: #fff;
          background-color: #f35a5a;
        }
        .name {
          font-size: 20px;
          color: #636363;
          margin-top: 10px;
        }
        .num {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 16px;
          margin-top: 10px;
          .title {
            color: #7d7d7d;
          }
          .num-content {
            color: #a7a7a7;
          }
        }
        .warn {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 20px;
          font-size: 14px;
        }
        .pdf {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          color: #f35a5a;
          cursor: pointer;
          &:hover {
            span {
              color: #f35a5a !important;
            }
          }
        }
      }
      .right {
        flex: 1;
        height: 100%;
        margin:0px 20px;
        overflow: auto;
        .exam-item {
          margin-bottom: 20px;
          padding: 10px 20px;
          border-radius: 10px;
          background-color: #fff;
          width: 100%;
          min-height: 120px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          transition: all 0.3s;
          &:hover {
            transition: all 0.3s;
            background-color: #efefef;
          }
          .item-left {
            width: 80%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;
            .title {
              font-size: 24px;
              color: #636363;
              font-weight: bold;
            }
            .item-title {
              font-size: 20px;
              font-weight: bold;
              color: #636363;
            }
            .item-info {
              width: 100%;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              flex-wrap: wrap;
              margin-top: 10px;
              .info-detail {
                width: 50%;
                font-size: 14px;
                color: #a7a7a7;
              }
            }
          }
        }
      }
    }
  }
}
</style>
