var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "telecom-container" }, [
    _vm._m(0),
    _c("div", { staticClass: "telecom-content" }, [
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "left" }, [
          _c("div", { staticClass: "top" }, [
            _c("div", { staticClass: "avatar" }, [
              _vm._v(" " + _vm._s(_vm.name.charAt(0)) + " "),
            ]),
            _c("div", { staticClass: "name" }, [_vm._v(_vm._s(_vm.name))]),
            _c("div", { staticClass: "num" }, [
              _c("span", { staticClass: "title" }, [_vm._v("员工号：")]),
              _c("span", { staticClass: "num-content" }, [
                _vm._v(_vm._s(_vm.degree)),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "bottom" }, [
            _c("div", { staticClass: "pdf", on: { click: _vm.downLoadPDF } }, [
              _c("i", {
                staticClass: "iconfont iconcaozuozhinan",
                staticStyle: { "font-size": "36px", "margin-right": "5px" },
              }),
              _c(
                "span",
                { staticStyle: { "font-weight": "bold", color: "#000" } },
                [_vm._v("查看考试系统指引")]
              ),
            ]),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "right" },
          _vm._l(_vm.examList, function (item) {
            return _c("div", { key: item.examId, staticClass: "exam-item" }, [
              _c("div", { staticClass: "item-left" }, [
                _c("div", { staticClass: "item-title" }, [
                  _vm._v(_vm._s(item.examName)),
                ]),
                _c("div", { staticClass: "item-info" }, [
                  _c("div", { staticClass: "info-detail" }, [
                    _c("span", { staticStyle: { color: "#a7a7a7" } }, [
                      _vm._v("考试时间："),
                    ]),
                    _c("span", { staticStyle: { color: "#7d7d7d" } }, [
                      _vm._v(_vm._s(_vm.timeTrans(item.startAt))),
                    ]),
                  ]),
                  _c("div", { staticClass: "info-detail" }, [
                    _c("span", { staticStyle: { color: "#a7a7a7" } }, [
                      _vm._v("考试状态："),
                    ]),
                    _c(
                      "span",
                      {
                        style:
                          _vm.getStatus(item.startAt, item.endAt) === "考试中"
                            ? "color: #f35a5a;"
                            : "color: #7d7d7d;",
                      },
                      [_vm._v(_vm._s(_vm.getStatus(item.startAt, item.endAt)))]
                    ),
                  ]),
                  _c("div", { staticClass: "info-detail" }, [
                    _c("span", { staticStyle: { color: "#a7a7a7" } }, [
                      _vm._v("考试时长："),
                    ]),
                    _c("span", { staticStyle: { color: "#7d7d7d" } }, [
                      _vm._v(_vm._s(item.duration / 60) + "分钟"),
                    ]),
                  ]),
                  _c("div", { staticClass: "info-detail" }, [
                    _c("span", { staticStyle: { color: "#a7a7a7" } }, [
                      _vm._v("考试成绩："),
                    ]),
                    _c("span", { staticStyle: { color: "#7d7d7d" } }, [
                      _vm._v(_vm._s(item.finalScore || "--") + "分"),
                    ]),
                  ]),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "item-right" },
                [
                  _vm.getStatus(item.startAt, item.endAt) === "已结束"
                    ? _c(
                        "el-button",
                        {
                          staticStyle: { width: "98px" },
                          attrs: { type: "info", disabled: "" },
                        },
                        [_vm._v("已结束")]
                      )
                    : _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.goExam(item)
                            },
                          },
                        },
                        [_vm._v("进入考试")]
                      ),
                ],
                1
              ),
            ])
          }),
          0
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "header" }, [
      _c("img", {
        staticClass: "logo",
        attrs: { src: require("@/assets/images/telecom.jpg"), alt: "" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }